import React from 'react';
import styled, { withTheme } from 'styled-components';

import { PATHS } from '../constants/paths';

import { Layout, Section } from '@components/Layout';
import { Column, Place, Row } from '@lib/nvstr-common-ui.es';
import { Body1, Body2, Bold, H2 } from '@components/Text';
import { BannerBlog } from '@components/Blocks';

import BackgroundHeroWithTornadoOutlines from '@components/Backgrounds/BackgroundHeroWithTornadoOutlines';
import Link from 'next/link';
import Anchor from '@components/Primitives/Anchor';
import { TrackingEvents } from '../tracking';

const pageLinksList = [
  {
    text: 'Home',
    url: PATHS.HOME,
  },
  {
    text: 'About',
    url: PATHS.ABOUT,
  },
  {
    text: 'FAQ',
    url: PATHS.FAQ,
  },
  {
    text: 'Legal',
    url: PATHS.LEGAL,
  },
];

const PageLinkWrapper = styled.div`
  width: ${(props) => (props.theme.isMobile ? '80px' : '100px')};
  padding: 0px 4px;

  a {
    color: ${(props) =>
      props.isDarkMode
        ? props.theme.primary.oak
        : props.theme.primary.charcoal};
    text-decoration: underline;
  }
`;

const PageLinks = ({ link, isDarkMode, theme }) => {
  return (
    <PageLinkWrapper theme={theme}>
      <Link href={link.url} passHref>
        <Anchor>
          <div style={{ padding: '16px 4px', textAlign: 'center' }}>
            <Body2 isDarkMode={isDarkMode}>{link.text}</Body2>
          </div>
        </Anchor>
      </Link>
    </PageLinkWrapper>
  );
};

const Page = ({ isDarkMode, theme }) => {
  return (
    <Section>
      <Column padding="100px 20px">
        <Row hAlign="center">
          <Place>
            <H2 isDarkMode={isDarkMode}>
              Page
              <br />
              <Bold>Not Found</Bold>
            </H2>
            <Place padding="16px 0 0 0">
              <Body1 isDarkMode={isDarkMode}>
                We can&apos;t seem to find the page you are looking for.
              </Body1>
            </Place>
          </Place>
        </Row>
        <Row padding="100px 0 0 0" hAlign="center">
          <Place>
            <div style={{ textAlign: 'center' }}>
              <Body1 isDarkMode={isDarkMode} textAlign="center">
                Let&apos;s get you back on track
              </Body1>
            </div>
            <Place padding="16px 0 0 0">
              <Row maxWidth="400px" style={{ justifyContent: 'space-between' }}>
                {pageLinksList.map((l, i) => (
                  <PageLinks key={i} link={l} theme={theme} />
                ))}
              </Row>
            </Place>
          </Place>
        </Row>
      </Column>
    </Section>
  );
};

const FourOhFour = ({ theme }) => {
  const { isMobile } = theme;
  const isDarkMode = false;

  React.useEffect(() => {
    TrackingEvents.application.view404.send({ URL: window.location.pathname });
  }, []);

  return (
    <Layout
      pageTitle={'Tornado - Page Not Found'}
      section="404"
      banner={<BannerBlog />}
      background={
        <BackgroundHeroWithTornadoOutlines
          topPosition={isMobile ? '-200px' : '-100px'}
          leftPosition={isMobile ? '-500px' : '0px'}
          isDarkMode={isDarkMode}
        />
      }
      useTransparentHeader
    >
      <Page isDarkMode={isDarkMode} theme={theme} />
    </Layout>
  );
};

export default withTheme(FourOhFour);
