import React from 'react';
import styled, { withTheme } from 'styled-components';
import TornadoLines from '@components/Images/TornadoLines';
import Section from '@components/Layout/Section';
import { Place } from '@lib/nvstr-common-ui.es';

const Background = styled.div`
  width: 100%;
  height: 1443px;
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props) => props.background};
  z-index: -1;
`;

const BackgroundHeroWithLines = (props) => {
  const { theme, isMobile, isDarkMode, topPosition, leftPosition } = props;
  const bgColor = isDarkMode ? theme.primary.charcoal : theme.primary.oak;

  if (isMobile) {
    return (
      <Background background={bgColor}>
        <Section>
          <Place
            position="absolute"
            top={topPosition || '0px'}
            left={leftPosition || '-300px'}
          >
            <TornadoLines isDarkMode={isDarkMode} isMobile />
          </Place>
        </Section>
      </Background>
    );
  }

  return (
    <Background background={bgColor}>
      <Section>
        <Place
          position="absolute"
          top={topPosition || '0px'}
          left={leftPosition || '0px'}
        >
          <TornadoLines isDarkMode={isDarkMode} />
        </Place>
      </Section>
    </Background>
  );
};

export default withTheme(BackgroundHeroWithLines);
